<template>
  <v-data-table
    :headers="headers"
    :items="allJadwal"
    :items-per-page="10"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>DATA JADWAL SPMB UNIKARTA</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
              >New Item</v-btn
            >
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.nm_jadwal"
                      label="Nama Jadwal"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="editedItem.tanggal_mulai"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.tanggal_mulai"
                          label="Tanggal Mulai"
                          prepend-icon="fas fa-calendar-alt"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.tanggal_mulai"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(editedItem.tanggal_mulai)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-- <v-text-field
                      v-model="editedItem.tanggal_akhir"
                      label="Tanggal Akhir"
                    ></v-text-field> -->
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="editedItem.tanggal_akhir"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.tanggal_akhir"
                          label="Tanggal Akhir"
                          prepend-icon="fas fa-calendar-alt"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.tanggal_akhir"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(editedItem.tanggal_akhir)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.tahun_penerimaan"
                      label="Tahun Penerimaan"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.is_aktiv"
                      label="Aktiv"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field
        v-model="search"
        label="Search (UPPER CASE ONLY)"
        class="mx-4"
      ></v-text-field>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        fas fa-edit
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        far fa-trash-alt
      </v-icon>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-btn
        v-if="item.is_aktiv === '1'"
        class="ma-2"
        text
        icon
        color="blue lighten-2"
      >
        <v-icon @click="aktiv(item)">mdi-thumb-up</v-icon>
      </v-btn>
      <v-btn v-else class="ma-2" text icon color="red lighten-2">
        <v-icon @click="notAktiv(item)">mdi-thumb-down</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Jadwal",
  data: () => ({
    menu: false,
    menu2: false,
    dialog: false,
    search: "",
    editedIndex: -1,
    editedItem: {
      nm_jadwal: "",
      tanggal_mulai: new Date().toISOString().substr(0, 10),
      tanggal_akhir: new Date().toISOString().substr(0, 10),
      tahun_penerimaan: "",
      is_aktiv: ""
    },
    defaultItem: {
      nm_jadwal: "",
      tanggal_mulai: "",
      tanggal_akhir: "",
      tahun_penerimaan: "",
      is_aktiv: ""
    }
  }),
  created() {
    this.loadJadwal();
  },
  computed: {
    ...mapGetters("jadwals", ["allJadwal"]),
    headers() {
      return [
        { text: "Nama Jadwal", value: "nm_jadwal" },
        { text: "Tanggal Mulai", value: "tanggal_mulai" },
        { text: "Tanggal Akhir", value: "tanggal_akhir" },
        { text: "Tahun", value: "tahun_penerimaan" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false }
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Jadwal" : "Edit Jadwal";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions("jadwals", ["loadJadwal"]),
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
    editItem(item) {
      this.editedIndex = this.allJadwal.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.$store.dispatch("jadwals/removeJadwal", item);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.loadJadwal();
    },

    save() {
      if (this.editedIndex > -1) {
        this.$store.dispatch("jadwals/updateJadwal", this.editedItem);
      } else {
        this.$store.dispatch("jadwals/addJadwal", this.editedItem);
      }
      this.close();
    },
    aktiv(item) {
      this.$store
        .dispatch("jadwals/updateJadwal", {
          id: item.id,
          nm_jadwal: item.nm_jadwal,
          tanggal_mulai: item.tanggal_mulai,
          tanggal_akhir: item.tanggal_akhir,
          tahun_penerimaan: item.tahun_penerimaan,
          is_aktiv: "2"
        })
        .then(() => {
          this.loadJadwal();
        });
    },
    notAktiv(item) {
      this.$store
        .dispatch("jadwals/updateJadwal", {
          id: item.id,
          nm_jadwal: item.nm_jadwal,
          tanggal_mulai: item.tanggal_mulai,
          tanggal_akhir: item.tanggal_akhir,
          tahun_penerimaan: item.tahun_penerimaan,
          is_aktiv: "1"
        })
        .then(() => {
          this.loadJadwal();
        });
    }
  }
};
</script>

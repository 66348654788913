var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allJadwal,"items-per-page":10,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("DATA JADWAL SPMB UNIKARTA")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("New Item")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Nama Jadwal"},model:{value:(_vm.editedItem.nm_jadwal),callback:function ($$v) {_vm.$set(_vm.editedItem, "nm_jadwal", $$v)},expression:"editedItem.nm_jadwal"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.editedItem.tanggal_mulai,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedItem, "tanggal_mulai", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedItem, "tanggal_mulai", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Mulai","prepend-icon":"fas fa-calendar-alt","readonly":""},model:{value:(_vm.editedItem.tanggal_mulai),callback:function ($$v) {_vm.$set(_vm.editedItem, "tanggal_mulai", $$v)},expression:"editedItem.tanggal_mulai"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.editedItem.tanggal_mulai),callback:function ($$v) {_vm.$set(_vm.editedItem, "tanggal_mulai", $$v)},expression:"editedItem.tanggal_mulai"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.editedItem.tanggal_mulai)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.editedItem.tanggal_akhir,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedItem, "tanggal_akhir", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedItem, "tanggal_akhir", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Akhir","prepend-icon":"fas fa-calendar-alt","readonly":""},model:{value:(_vm.editedItem.tanggal_akhir),callback:function ($$v) {_vm.$set(_vm.editedItem, "tanggal_akhir", $$v)},expression:"editedItem.tanggal_akhir"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.editedItem.tanggal_akhir),callback:function ($$v) {_vm.$set(_vm.editedItem, "tanggal_akhir", $$v)},expression:"editedItem.tanggal_akhir"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.editedItem.tanggal_akhir)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Tahun Penerimaan"},model:{value:(_vm.editedItem.tahun_penerimaan),callback:function ($$v) {_vm.$set(_vm.editedItem, "tahun_penerimaan", $$v)},expression:"editedItem.tahun_penerimaan"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Aktiv"},model:{value:(_vm.editedItem.is_aktiv),callback:function ($$v) {_vm.$set(_vm.editedItem, "is_aktiv", $$v)},expression:"editedItem.is_aktiv"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search (UPPER CASE ONLY)"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" fas fa-edit ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" far fa-trash-alt ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.is_aktiv === '1')?_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","color":"blue lighten-2"}},[_c('v-icon',{on:{"click":function($event){return _vm.aktiv(item)}}},[_vm._v("mdi-thumb-up")])],1):_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":"","color":"red lighten-2"}},[_c('v-icon',{on:{"click":function($event){return _vm.notAktiv(item)}}},[_vm._v("mdi-thumb-down")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }